import React, { useContext, useEffect } from "react";
import { AuthContext, useAuthContext } from "../contexts/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";
import Cookies from "js-cookie"; 

const Layout = () => {
  const { logout } = useContext(AuthContext);
  const { userLoggedIn, user } = useAuthContext();

  const { t } = useTranslation();
  const { getBaseData } = useContext(AuthContext);
  const { baseData } = useAuthContext();

  const { i18n } = useTranslation();
  const { setToAllLangs } = useContext(AuthContext);

  useEffect(() => {
    i18n.changeLanguage(Cookies.get("lang") ?? "en");
    const langs = JSON.parse(localStorage.getItem("allLangs"));
    setToAllLangs(langs, true);
    if (langs)
      langs.forEach((language) => {
        const { code, contents } = language;
        const translation = contents; // استخدم محتوى اللغة كترجمة
        // قم بإضافة المفاتيح والترجمة للغة المحددة باستخدام addResourceBundle
        i18n.addResourceBundle(code, "translation", translation, true, true);
      });

    if (baseData == null) {
      getBaseData()
        .then((response) => {})
        .catch((error) => {
          if(error.response?.data?.message){
            toast.error(
               t("An error occurred retrieving data") + error.response.data.message
            );
            
            console.log(error.response.data.message);
          }
         else{
          toast.error(
           t("An unknown error has occurred")
          );
          console.log(error);
         }
        });
    }
  }, []);

  return (
    <div>
      <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="#" className="flex items-center">
            <img
              src="https://flowbite.com/docs/images/logo.svg"
              className="h-8 mr-3"
              alt="Flowbite Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              {t("OUMND")}
            </span>
          </a>
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full md:block md:w-auto"
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                     isActive ? "active block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" 
                     : "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    }
 
                >
                  {t("Home")}
                </NavLink>
              </li>
              {userLoggedIn ? (
                <>
                  <li>
                    <NavLink
                      to="/dashboard"
                      className={({ isActive }) =>
                      isActive ? "active block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" 
                      : "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                     }>
                      {t("Dashboard")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/apps"
                      className={({ isActive }) =>
                      isActive ? "active block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" 
                      : "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                     }>
                      {t("Apps")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/offers"
                      className={({ isActive }) =>
                      isActive ? "active block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" 
                      : "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                     } >
                      {t("Offers")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/invoices"
                      className={({ isActive }) =>
                      isActive ? "active block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" 
                      : "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                     }>
                      {t("Invoices")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/profile"
                      className={({ isActive }) =>
                      isActive ? "active block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent" 
                      : "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                     } >
                      {t("My Profile")}
                    </NavLink>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li>
                <LanguageSwitcher />
              </li>
              <li>
                {userLoggedIn ? (
                  <button
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    onClick={logout}
                  >
                    {t("Logout")}
                  </button>
                ) : (
                  <>
                    <NavLink
                      to="/login"
                      className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    >
                      {t("Login")}
                    </NavLink>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main className="p-5">
        <Outlet />
      </main>
      <footer>
        footer
        {/* أضف أي عناصر تذيل الصفحة هنا */}
      </footer>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Layout;
