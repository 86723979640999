import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import { ChoroplethController, GeoFeature, ColorScale, ProjectionScale } from 'chartjs-chart-geo';

Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);

const WorldMap = () => {
  useEffect(() => {
     
  }, []);

  return (
    <div>
      word
    </div>
  );
};

export default WorldMap;