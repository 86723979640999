"use client";
import React, { useContext, useEffect, useState } from "react";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-hot-toast";
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";
import { CheckSession } from "../../functions/forms";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Apps() {
  const { getApps, updateApp, createApp } = useContext(HomeContext);

  const { apps } = useHomeContext(); 

  const [verticalTab, setVerticalTab] = useState(1);
  const [horizontalTab, setHorizontalTab] = useState(0);

  const [idApp, setIdApp] = useState(0);
  const [appName, setAppName] = useState("");
  const [appUrl, setAppUrl] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencyExchangeUsd, setCurrencyExchangeUsd] = useState(0);
  const [isWall, setIsWall] = useState(0);
  const [currencyDisplayPreference, setCurrencyDisplayPreference] = useState(0);
  const [type, setType] = useState("all");
  const [description, setDescription] = useState("");
  const [statusApp, setStatusApp] = useState(0); 

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = () => {
      // استدعاء getApps وانتظار الاستجابة
      getApps()
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data) {
            toast.error(t('An error occurred retrieving data') + error.response.data.message
            );
            // if (error.response.status === 401) {
            //   logout();
            //   CheckSession(userLoggedIn, user);
            // }
            console.log(error.response.data.message);
          } else {
            console.log(error);
            toast.error(t("An unknown error has occurred"));
          }
        });
    };

    fetchData();
  }, []);
  useEffect(() => {
    try {
      handleTabClick(apps.data[0]);
    } catch (error) {}

    // استدعاء handleTabClick بعد الاستجابة من getApps
  }, [apps]);
  const handleUpdateApp = async () => {
    const data = {
      app_name: appName,
      app_url: appUrl,
      currency_name: currencyName,
      currency_exchange_usd: currencyExchangeUsd,
      is_wall: isWall,
      currency_display_preference: currencyDisplayPreference,
      type: type,
      description: description,
      status: statusApp,
    };
    setLoading(true);
    updateApp(idApp, data)
      .then((response) => {
        setLoading(false);
        toast.success(response.message);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred retrieving data");
        console.log(error.response.data.message);
      });
  };

  const handleCreateApp = async () => {
    const data = {
      app_name: appName,
      app_url: appUrl,
      currency_name: currencyName,
      currency_exchange_usd: currencyExchangeUsd,
      is_wall: isWall,
      currency_display_preference: currencyDisplayPreference,
      type: type,
      description: description,
      status: statusApp,
    };
    setLoading(true);
    const res = await createApp(data);
    setLoading(false);
    console.log(res);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const handleChangeHorizontal = (event, newValue) => {
    setHorizontalTab(newValue);
  };
  const handleChangeVertical = (event, newValue) => {
    setVerticalTab(newValue);
  };
  const handleTabClick = (app) => {
    if (app) {
      setIdApp(app.id);
      setAppName(app.app_name);
      setAppUrl(app.app_url);
      setCurrencyName(app.currency_name);
      setCurrencyExchangeUsd(app.currency_exchange_usd);
      setIsWall(app.is_wall);
      setCurrencyDisplayPreference(app.currency_display_preference);
      setType(app.type);
      setStatusApp(app.status);
    } else {
      setIdApp(0);
      setAppName("");
      setAppUrl("");
      setCurrencyName("");
      setCurrencyExchangeUsd(0);
      setIsWall(0);
      setCurrencyDisplayPreference(0);
      setType("all");
      setStatusApp(1);
    }
  };

  const handleChangeIsWall = (e) => {
    setIsWall(e.target.value);
    console.log(e.target.value);
  };
  const handleChangeType = (e) => {
    setType(e.target.value);
    console.log(e.target.value);
  };

  const handleChangeDisplayPreference = (e) => {
    setCurrencyDisplayPreference(e.target.value);
    console.log(e.target.value);
  };
  const handleChangeStatusApp = (e) => {
    setStatusApp(e.target.value);
    console.log(e.target.value);
  };

  if (!apps) {
    return <>loading ...</>;
  }
  return (
    <div>
      <Box
        component={"span"}
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={verticalTab}
          onChange={handleChangeVertical}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            key={0}
            label={"Create App"}
            {...a11yProps(0)}
            onClick={() => handleTabClick(false)}
          />
          {apps.data.map((app, index) => (
            <Tab
              key={app.id}
              label={app.app_name}
              {...a11yProps(index + 1)}
              onClick={() => handleTabClick(app)}
            />
          ))}
        </Tabs>
        <TabPanel key={0} value={verticalTab} index={0}>
          <TextField
            id="outlined-basic"
            label="app Name"
            variant="outlined"
            value={appName}
            name="appName"
            onChange={(e) => setAppName(e.target.value)}
            placeholder="DoeApp"
            size="small"
            margin="normal"
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="app Url"
            variant="outlined"
            size="small"
            value={appUrl}
            onChange={(e) => setAppUrl(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="currency Name"
            variant="outlined"
            value={currencyName}
            onChange={(e) => setCurrencyName(e.target.value)}
            placeholder="coins"
            size="small"
            margin="normal"
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="currency Exchange Usd"
            variant="outlined"
            value={currencyExchangeUsd}
            onChange={(e) => setCurrencyExchangeUsd(e.target.value)}
            placeholder="1000"
            size="small"
            margin="normal"
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="and description"
            size="small"
            margin="normal"
            fullWidth
          />

         
          <InputLabel id="demo-simple-select-label">is wall</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={isWall}
            label="is wall"
            onChange={handleChangeIsWall}
          >
            <MenuItem value={0}>Api</MenuItem>
            <MenuItem value={1}>Wall</MenuItem>
          </Select>
          <InputLabel id="demo-simple-select-label">
            currencyDisplayPreference
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currencyDisplayPreference}
            label="currencyDisplayPreference"
            onChange={handleChangeDisplayPreference}
          >
            <MenuItem value={0}>Dollar</MenuItem>
            <MenuItem value={1}>Virtual Currency</MenuItem>
          </Select>

          <InputLabel id="demo-simple-select-label">type offers</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="type"
            onChange={handleChangeType}
          >
            <MenuItem value="all">all</MenuItem>
            <MenuItem value="offer">offer</MenuItem>
            <MenuItem value="survey">survey</MenuItem>
            <MenuItem value="ads">ads</MenuItem>
          </Select>

          <InputLabel id="demo-simple-select-label">status App</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statusApp}
            label="status App"
            onChange={handleChangeStatusApp}
          >
            <MenuItem value={0}>{t('Disabled')}</MenuItem>
            <MenuItem value={1}>{t('Enable')}</MenuItem>
          </Select>

          <br />
          <br />
          
          <LoadingButton
            onClick={handleCreateApp}
            loading={loading}  
            loadingIndicator={t("Loading…")} 
            variant="contained" 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t('create')}
          </LoadingButton>
        </TabPanel>
        {apps.data.map((app, index) => (
          <TabPanel key={app.id} value={verticalTab} index={index + 1}>
            {app.approved == 2 ? (
              "not approved yet"
            ) : (
              <>
                {t('Name')} : {app.app_name} <br />
                {t('Api key')} : {app.api_key} <br />
                {t('website the app')} : {app.app_url} <br />
                {t('status')} : {app.status} <br />
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={horizontalTab}
                    onChange={handleChangeHorizontal}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Item One" {...a11yProps(0)} />
                    {/* <Tab label="Item Two" {...a11yProps(1)} />
                    <Tab label="Item Three" {...a11yProps(2)} /> */}
                  </Tabs>
                </Box>
                <TabPanel value={horizontalTab} index={0} component={"div"}>
                  <TextField
                    id="outlined-basic"
                    label={t("app Name")}
                    variant="outlined"
                    value={appName}
                    name="appName"
                    onChange={(e) => setAppName(e.target.value)}
                    placeholder="DoeApp"
                    size="small"
                    margin="normal"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label={t("app Url")}
                    variant="outlined"
                    size="small"
                    value={appUrl}
                    margin="normal"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label={t("currency Name")}
                    variant="outlined"
                    value={currencyName}
                    onChange={(e) => setCurrencyName(e.target.value)}
                    placeholder="coins"
                    size="small"
                    margin="normal"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label={t("currency Exchange Usd")}
                    variant="outlined"
                    value={currencyExchangeUsd}
                    onChange={(e) => setCurrencyExchangeUsd(e.target.value)}
                    placeholder="1000"
                    size="small"
                    margin="normal"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label={t("description")}
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="and description"
                    size="small"
                    margin="normal"
                    fullWidth
                  />
                  
                  <InputLabel id="demo-simple-select-label">is wall</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={isWall}
                    label={t("is wall")}
                    onChange={handleChangeIsWall}
                  >
                    <MenuItem value={0}>{t('Api')}</MenuItem>
                    <MenuItem value={1}>{t('Wall')}</MenuItem>
                  </Select>
                  <InputLabel id="demo-simple-select-label">
                    {t('currencyDisplayPreference')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currencyDisplayPreference}
                    label={t("currencyDisplayPreference")}
                    onChange={handleChangeDisplayPreference}
                  >
                    <MenuItem value={0}>{t('Dollar')}</MenuItem>
                    <MenuItem value={1}>{t('Virtual Currency')}</MenuItem>
                  </Select>

                  <InputLabel id="demo-simple-select-label">
                    {t('type offers')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label={t("type")}
                    onChange={handleChangeType}
                  >
                    <MenuItem value="all">all</MenuItem>
                    <MenuItem value="offer">offer</MenuItem>
                    <MenuItem value="survey">survey</MenuItem>
                    <MenuItem value="ads">ads</MenuItem>
                  </Select>

                  <InputLabel id="demo-simple-select-label">
                    {t('status App')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusApp}
                    label= {t('status App')}
                    onChange={handleChangeStatusApp}
                  >
                    <MenuItem value={0}>Disabled</MenuItem>
                    <MenuItem value={1}>Enable</MenuItem>
                  </Select>

                  <br />
                  <br />
                  <LoadingButton
                    onClick={handleUpdateApp}
                    loading={loading} 
                    loadingIndicator={t("Loading…")}  
                    variant="contained"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    {t('edit')}
                  </LoadingButton>
                   
                </TabPanel>
                {/* <TabPanel value={horizontalTab} index={1}>
                  Item Two
                </TabPanel>
                <TabPanel value={horizontalTab} index={2}>
                  Item Three
                </TabPanel> */}
              </>
            )}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
}

export default Apps;
