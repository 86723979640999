import React from 'react'
import { useHomeContext } from '../../contexts/HomeContext';
 
 
 
function News() {
    const { data } = useHomeContext();
  return (
    <div className="w-full md:w-1/2 lg:w-2/3 ">
        <h2>{data.data.news.title}</h2>  
        {data.data.news.data.map((post) => {
          return <p key={post.id}>{post.title} :{post.body}</p>
        })}
    </div> 
  )
}

export default News