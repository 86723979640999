// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './lang/en/translation.json';
import arTranslation from './lang/ar/translation.json';

const resources = {
  en: {
    translation: enTranslation
  },
  ar: {
    translation: arTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ch', // اللغة الافتراضية
    fallbackLng: 'en', // اللغة التي سيتم الاستعادة إليها إذا لم تكن اللغة المطلوبة متاحة
    interpolation: {
      escapeValue: false // تعطيل ترميز القيم المحاطة بنصوص مزدوجة معينة
    }
  });

export default i18n; 