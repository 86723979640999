'use client';
import { createContext, useContext, useState } from 'react';
import api from '../axios/api';

export const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
  const [data, setData] = useState(); 
  const [apps, setApps] = useState(); 
  const [allOffers, setAllOffers] = useState(); 
  const [allInvoices, setAllInvoices] = useState(); 
  const [offersDisabled, setOffersDisabled] = useState(); 
  const [dataUser, setDataUser] = useState(); 
  
  
  const getData = async (selectedRange) => { 
    console.log(selectedRange);
    
    console.log(2);
        const response = await api.get('/apps/details', {
          params: {
            start_date: selectedRange.startDate,
            end_date: selectedRange.endDate
          }
        });  
        setData(response.data.data);
        
    console.log(4);
        console.log(response.data.data); 
  };
  const getApps = async () => { 
    const response = await api.get('/apps');  
    setApps(response.data.data);
    console.log(response.data.data); 
};

const getOffers = async () => { 
  const response = await api.get('/offers');  
  setAllOffers(response.data.data);
  console.log(response.data.data); 
};
const getOffersDisabled = async () => { 
  const response = await api.get('/offers/disabled');  
  setOffersDisabled(response.data.data);
  console.log(response.data.data); 
};
  const updateApp = async (id,data) =>{
    console.log(data);
    const response = await api.put('/apps/'+id, data );  
    console.log( response.data.data );
    setApps(response.data.data);
    return response.data;
  }
  const createApp = async ( data) =>{ 
    console.log(data);
    const response = await api.post('/apps', data );   
    setApps(response.data.data);
    return response.data;
  }
  const bulkDisable = async ( data) =>{ 
    console.log(data);
    const response = await api.post('/offers/hide_offer', data );  
    console.log( response.data ); 
    return response.data;
  }
  
  const getInvoices = async () => { 
    const response = await api.get('/invoices');  
    setAllInvoices(response.data.data);
    console.log(response.data.data); 
  };

  const getProfile = async () => { 
    const response = await api.get('/users/my_profile');  
    setDataUser(response.data.data);
    console.log(response.data.data); 
  };

  const updateProfile = async (data) => { 
    const response = await api.post('/users/update_profile', data );  
    console.log( response.data ); 
    return response.data; 
  };

  const updatePassword = async (data) => { 
    const response = await api.post('/users/update_password', data );  
    console.log( response.data ); 
    return response.data; 
  };

  const updatePayment = async (data) => { 
    console.log(data ); 
    const response = await api.post('/users/add_method_payment_to_user', data );  
    console.log( response.data ); 
    return response.data; 
  };

  

  return (
    <HomeContext.Provider value={{ 
      data, 
      getData,
      setData,
      apps,
      getApps,
      updateApp ,
      createApp,
      getOffers,
      getOffersDisabled,
      allOffers,
      offersDisabled,
      bulkDisable,
      allInvoices,
      getInvoices,
      getProfile,
      dataUser,
      updateProfile,
      updatePassword,
      updatePayment
      }}>
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = () => useContext(HomeContext);
