 
import React, { useContext, useEffect } from "react"; 
import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Details } from "../../sections/profile/details";
import { Password } from "../../sections/profile/password"; 
import { PaymentMethod } from "../../sections/profile/PaymentMethod";
import { HomeContext } from "../../contexts/HomeContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { CheckSession } from "../../functions/forms";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
 

function Profile() {
  const { getProfile } = useContext(HomeContext);  
  const { t } = useTranslation();
  useEffect(() => { 
    getProfile().then(response => { })
    .catch(error => { 
      if(error.response?.data){
        toast.error(  t("An error occurred retrieving data") + error.response?.data?.message ); 
        console.log(error.response.data.message);
      }
      else{
        toast.error(t("An unknown error has occurred"));
        console.log(error);
      }
      

      });  
  }, []);
 
   

  return (
    <>
       
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Account</Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12} md={6} lg={8}>
                  <Details />
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  {/* <Password /> */}
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  <Password />
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  <PaymentMethod />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Profile;
