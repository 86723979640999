"use client";
import { useContext, useEffect, useState } from "react";
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";

import { Toaster, toast } from "react-hot-toast";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BsSendFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginApi } = useContext(AuthContext);
  const { userLoggedIn } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (userLoggedIn) navigate(-1);
  }, []);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await loginApi(email, password);
      setLoading(false);
      if (response.data.status) {
        toast.success(response.data.message);
        // router.push('/dashboard');
        window.location.href = "/dashboard";
      } else {
        toast.error(response.data.message);
      }
      console.log(response.data.status);
      // location.href = '/dashboard';
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.error(t("An error occurred while logging in. Please try again."));
      }
    }
  };

  return (
    <div className="p-10">
      <TextField
        label={t("email")}
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        required
        value={email}
      />
      <TextField
        label={t("password")}
        name="password"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        required
        value={password}
      />
     
      <LoadingButton
        onClick={handleLogin}
        loading={loading}
        endIcon={<BsSendFill />}
        loadingPosition="end"
        variant="contained"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        { t("login") }
      </LoadingButton>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Login;
