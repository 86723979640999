import React from 'react'


function StatsComponent({ title, data, present }) {
    let textColor = "";
    let sign = "";
    
    const presentValue = parseFloat(present);
    
    if (presentValue > 0) {
      textColor = "text-green-500";
      sign = "+";
    } else if (presentValue < 0) {
      textColor = "text-red-500";
    }
    
    return (
      <div className="bg-gray-500 p-4 rounded-lg">
        <div className="text-xl font-bold mb-2">{title}</div>
        <div className="text-2xl font-bold mb-2">{data}</div>
        <div className={`text-lg ${textColor}`}>
          {present ? (
            <>
              {sign}
              {Math.abs(presentValue)}%
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  

export default StatsComponent