'use client';
import { useContext } from "react"; 
  
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function Home() {
  const { user,token } = useAuthContext();
  const { logout} = useContext(AuthContext);
  const { t } = useTranslation();
  return ( 
      <main className="flex min-h-screen flex-col items-center justify-between p-24">
        <div className="relative flex place-items-center ">
        <h1>{t('Posts')}   {user ? user.name : ''}</h1>
        </div>
        {token ?<button onClick={logout} >{t('logout')}</button> : <Link href="/login">{t('login')}</Link> }
        
      </main> 
  );
}
