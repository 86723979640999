import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
 import { toast } from "react-hot-toast";
import { HomeContext } from "../../contexts/HomeContext";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

export const Password = () => {
  const { updatePassword } = useContext(HomeContext);
  const [values, setValues] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await updatePassword(values);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid>
                <TextField
                  fullWidth
                  helperText="Please specify the first name"
                  label={ t("old password") }
                  name="old_password"
                  onChange={handleChange}
                  required
                  value={values.old_password}
                />
              </Grid>
              <Grid>
                <TextField
                  fullWidth
                  label={ t("new password") }
                  name="new_password"
                  onChange={handleChange}
                  required
                  value={values.last_name}
                />
              </Grid>
              <Grid>
                <TextField
                  fullWidth
                  label={ t("new password confirmation") }
                  name="new_password_confirmation"
                  onChange={handleChange}
                  type="number"
                  value={values.new_password_confirmation}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
           
          <LoadingButton
            type="submit"
            loading={loading}
            loadingIndicator="saving.."
            variant="contained"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
             { t("Save") }
          </LoadingButton>
        </CardActions>
      </Card>
    </form>
  );
};
