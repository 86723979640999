import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useHomeContext } from '../../contexts/HomeContext';
 

function ChartDoughnut() {
  const { data } = useHomeContext();
  const [chartData, setChartData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('clicks');
  const [selectedColor, setSelectedColor] = useState('rgba(255, 99, 132, 0.6)');

  useEffect(() => {
    if (data && data.data && data.data.chart_app) {
      let selectedData;
      if (selectedOption === 'clicks') {
        selectedData = data.data.chart_app.clicks;
        setSelectedColor('rgba(255, 99, 132, 0.6)');
      } else if (selectedOption === 'revenue') {
        selectedData = data.data.chart_app.revenue;
        setSelectedColor('rgba(54, 162, 235, 0.6)');
      } else if (selectedOption === 'reversals') {
        selectedData = data.data.chart_app.reversals;
        setSelectedColor('rgba(255, 206, 86, 0.6)');
      }

      // تحضير بيانات المخطط
      const chartData = {
        labels: selectedData.map(item => item.app_name),
        datasets: [
          {
            data: selectedData.map(item => {
              if (selectedOption === 'clicks') {
                return item.click_count;
              } else if (selectedOption === 'revenue') {
                return item.amount;
              } else if (selectedOption === 'reversals') {
                return 0; // يمكنك تعديل هذا حسب بيانات العكس
              }
            }),
            backgroundColor: selectedData.map(() => selectedColor),
          },
        ],
      };

      setChartData(chartData);
    }
  }, [data, selectedOption, selectedColor]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="clicks">Clicks</option>
        <option value="revenue">Revenue</option>
        <option value="reversals">Reversals</option>
      </select>
      {chartData ? (
        <Doughnut data={chartData} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
}

export default ChartDoughnut;