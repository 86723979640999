 
import axios from 'axios';
import Cookies from 'js-cookie';

// const api = axios.create({
//   // http://127.0.0.1:8000/api 
//   // https://oumnd.com/api
//   baseURL: 'http://127.0.0.1:8000/api', 
// });

const baseURL = window.location.hostname === 'localhost' ? 'http://127.0.0.1:8000/api' : 'https://oumnd.com/api';

const api = axios.create({
  baseURL: baseURL,
});


// قم بتعيين التوكن في الهيدر
const token = Cookies.get('token') ?? '';
const lang = Cookies.get('lang') ?? 'en';
api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
api.defaults.headers.common['X-localization'] =lang;

export default api;