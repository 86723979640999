import './App.css';
import Layout from './Layout/Layout';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { BrowserRouter as Router, Route, Navigate } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Apps from './pages/apps/Apps';
import Invoices from './pages/invoices/Invoices';
import Offers from './pages/offers/Offers';
import Profile from './pages/profile/Profile';
import Home from './pages/Home/Home';
import Cookies from 'js-cookie';
import { HomeProvider } from './contexts/HomeContext';
import NotLogged from './pages/errors/NotLogged';

function PrivateRoute({ path, element }) {
  const userLoggedIn = Cookies.get('userLoggedIn');

  if (userLoggedIn) {
    return  element ;
  } else {
    return    <NotLogged />;
  }
}

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <HomeProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route exact path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                <Route path="/apps" element={<PrivateRoute element={<Apps />} />} />
                <Route path="/invoices" element={<PrivateRoute element={<Invoices />} />} />
                <Route path="/offers" element={<PrivateRoute element={<Offers />} />} />
                <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
              </Route>
            </Routes>
          </Router>
          </HomeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;