

import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useHomeContext } from "../../contexts/HomeContext";

const ChartComponent = () => {
  const chartRef = useRef(null);
  const chartId = "myChart"; // معرف فريد للرسم البياني
  const days = [];
  const clicks_days = [];
  const completed_days = [];
  const revenue_days = [];
  const reversals_days = [];
  const { data } = useHomeContext(); 
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    data.data.chart_days.clicks.forEach((item) => {
      const day = item.year + "-" + item.month + "-" + item.day;
      days.push(day);
      clicks_days.push(item.click_count);
    });
    data.data.chart_days.completed.forEach((item) => {
      completed_days.push(item.click_count);
    });
    data.data.chart_days.revenue.forEach((item) => {
      revenue_days.push(item.click_count);
    });
    data.data.chart_days.reversals.forEach((item) => {
      reversals_days.push(item.click_count);
    });
    console.log(clicks_days);

    const existingChart = Chart.getChart(chartId);
    if (existingChart) {
      existingChart.destroy(); // تدمير الرسم البياني السابق
    }

    const newChartInstance = new Chart(ctx, {
      type: "line",
      data: {
        labels: days,
        datasets: [
          {
            label: data.data.chart_days.click_title,
            data: clicks_days,
            borderColor: data.data.chart_days.click_color,
            tension: 0.1,
          },
          {
            label: data.data.chart_days.completed_title,
            data: completed_days,
            borderColor: data.data.chart_days.completed_color,
            tension: 0.1,
          },
          {
            label: data.data.chart_days.revenue_title,
            data: revenue_days,
            borderColor: data.data.chart_days.revenue_color,
            tension: 0.1,
          },
          {
            label: data.data.chart_days.reversals_title,
            data: reversals_days,
            borderColor: data.data.chart_days.reversals_color,
            tension: 0.1,
          },
        ],
      },
    });
 
  }, [data]);

  return <canvas ref={chartRef} id={chartId} />;
};

export default ChartComponent;