"use client";
import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import api from "../axios/api";
import { useTranslation } from "react-i18next";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [lang, setLang] = useState();
  const [allLangs, setAllLangs] = useState([]);
  const [user, setUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [baseData, setBaseData] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLang = Cookies.get("lang");
    const storedToken = Cookies.get("token");
    const storedUser = Cookies.get("user");
    const storedUserLoggedIn = Cookies.get("userLoggedIn");

    setLang(storedLang);

    if (storedToken && storedUser) {
      setToken(storedToken);
      setUser(JSON.parse(storedUser));
      setUserLoggedIn(true);
      Cookies.set("userLoggedIn", storedUserLoggedIn);
    }
  }, []);

  const login = (newToken, newUser) => {
    setToken(newToken);
    setUser(newUser);
    setUserLoggedIn(true);
    Cookies.set("userLoggedIn", true);
    Cookies.set("token", newToken);
    Cookies.set("user", JSON.stringify(newUser));
  };

  const setToAllLangs = (code, isArray = false) => {
    if (isArray) {
      setAllLangs(code);
    } else {
      setAllLangs((prevCompanies) => {
        if(prevCompanies){
          const newLangs = [...prevCompanies, code];
          return newLangs;
        }
        const newLangs = [code];
          return newLangs;
      });
    }
  };

  const loginApi = async (email, password) => {
    const response = await api.post("/auth/login", { email, password });
    const { token, user } = response.data;
    login(token, user);
    return response;
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    setUserLoggedIn(false);
    Cookies.remove("userLoggedIn");
    Cookies.remove("token");
    Cookies.remove("user");
    // CheckSession(userLoggedIn, user);
    console.log("logout success");
  };

  const changeLang = (newLang) => {
    Cookies.set("lang", newLang);
    setLang(newLang);
    i18n.changeLanguage(newLang);
    // window.location.reload();
  };

  const getBaseData = async () => {
    const response = await api.get("/base_data");
    setBaseData(response.data.data);
    console.log(response.data.data);
    const languagesData = response.data.data.Language;
    localStorage.setItem("languagesData", JSON.stringify(languagesData));
    if (languagesData)
      languagesData.forEach((language) => {
        const { code, contents } = language;
        const translation = contents; // استخدم محتوى اللغة كترجمة

        setAllLangs((prevCompanies) => {
          if(prevCompanies){
            const newLangs = new Set([...prevCompanies, code]);
            return Array.from(newLangs);
          }
          const newLangs = new Set([code]);
          return Array.from(newLangs);

         
        });
        // قم بإضافة المفاتيح والترجمة للغة المحددة باستخدام addResourceBundle
        i18n.addResourceBundle(code, "translation", translation, true, true);
      });
    localStorage.setItem("allLangs", JSON.stringify(allLangs));

    console.log("getBaseData = ", response.data.data);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        userLoggedIn,
        user,
        login,
        logout,
        lang,
        changeLang,
        loginApi,
        baseData,
        getBaseData,
        allLangs,
        setToAllLangs,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
