 import React from 'react';
import { useHomeContext } from '../../contexts/HomeContext';
import { useTranslation } from 'react-i18next';

function TopOffers() {
    const { data } = useHomeContext();
    const { t } = useTranslation();
    console.log(data);
    return (
        <div className="w-full md:w-1/2 lg:w-2/3">
            <h2>{data.data.top_offers.title}</h2>
            <table>
                <thead>
                    <tr>
                        <th>  { t("offer name") }</th>
                        <th> { t("Number of clicks") }</th>
                        <th>  { t("platform") }</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.top_offers.data.map((post) => {
                        const offerData = JSON.parse(post.offer_data);
                        const osBrowser = JSON.parse(post.osBrowser);
                        const ip_info = JSON.parse(post.ip_info);
                        return (
                            <tr key={post.id}>
                                <td className='text-cyan-600 flex'>
                                    <img src={offerData.icon_url} width={28} alt={offerData.offer_name} />
                                    {offerData.offer_name.substring(0,20)}</td>
                                <td>{post.click_count}</td>
                                <td>{osBrowser.platform }</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default TopOffers;

