import React, { useContext, useEffect, useState } from "react";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Flag from "react-world-flags";
import { BsAndroid2, BsApple, BsWindows } from "react-icons/bs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { toast } from "react-hot-toast";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";
import { CheckSession } from "../../functions/forms";
import { useTranslation } from "react-i18next";

// //core
// import "primereact/resources/primereact.min.css";

function Offers() {
  const { getOffers, getOffersDisabled, bulkDisable } = useContext(HomeContext);
  const { allOffers, offersDisabled } = useHomeContext();
  const [tab, setTab] = useState("1");
  const { userLoggedIn, user } = useAuthContext();
  const { logout } = useContext(AuthContext);

  const [selectedOffer, setSelectedOffer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  // data table
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  // select
  const [selectedOffers, setSelectedOffers] = useState(null);
  const { t } = useTranslation();

  const fetchData = async () => {
    getOffers()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        if(error.response?.data){
          toast.error(
            t("An error occurred retrieving data") + error.response?.data?.message
          );
          
          console.log(error.response.data.message);
        }
        else{
          toast.error(t("An unknown error has occurred"));
          console.log(error);

        }
        
      });
    setLoading(false);
    initFilters();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openOfferDialog = (offer) => {
    setSelectedOffer(offer);
    setOpenDialog(true);
  };

  const closeOfferDialog = () => {
    setSelectedOffer(null);
    setOpenDialog(false);
  };

  const handleChangeTab = async (event, newValue) => {
    console.log(newValue);
    setLoading(true);
    if (newValue === 1) {
      const fetchData = async () => {
        await getOffers();
        setLoading(false);
      };

      fetchData();
    } else {
      const fetchData = async () => {
        await getOffersDisabled();
        setLoading(false);
      };

      fetchData();
    }

    setSelectedOffers(null);
    setTab(newValue);
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    console.log(value);
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      offer_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },

      // date: {
      //   operator: FilterOperator.AND,
      //   constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      // },
      // balance: {
      //   operator: FilterOperator.AND,
      //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      // },
      // status: {
      //   operator: FilterOperator.OR,
      //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      // },
      // activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      // verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const switchDevice = (offer) => {
    switch (offer.devices) {
      case "android":
        return <BsAndroid2 />;
      case "iphone_ipad":
      case "iphone":
      case "ipad":
        return <BsApple />;
      case "windows":
        return <BsWindows />;

      default:
        if (offer.devices) {
        }
        break;
    }
  };
  const titleOffer = (offer) => {
    return (
      <div className="flex ">
        <img src={offer.icon_url} width={50} alt={offer.offer_name} />
        <span
          className="p-4"
          onClick={() => openOfferDialog(offer)} // تفعيل النافذة المنبثقة عند النقر
          style={{ cursor: "pointer" }}
        >
          {offer.offer_name.substring(0, 50)}
        </span>
      </div>
    );
  };

  const countries = (offer) => {
    const parsedCountries = JSON.parse(offer.countries_array);
    const displayedCountries = parsedCountries
      .slice(0, 3)
      .map((val) => (
        <Flag key={val} code={val} style={{ width: "1.5em", margin: "5px" }} />
      ));

    const remainingCountriesCount = parsedCountries.length - 3;

    return (
      <div className="flex">
        {displayedCountries}
        {parsedCountries.length > 3 && <span>+{remainingCountriesCount}</span>}
      </div>
    );
  };

  const bulkDisableNow = async () => {
    const selectedIds = selectedOffers.map((offer) => offer.id);
    console.log(selectedIds);
    const data = {
      offer_id: selectedIds,
    };
    const res = await bulkDisable(data);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const bulkEnableNow = async () => {
    const selectedIds = selectedOffers.map((offer) => offer.id);
    console.log(selectedIds);
    const data = {
      offer_id: selectedIds,
      show: true,
    };
    const res = await bulkDisable(data);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label={t("Clear")}
          outlined
          onClick={clearFilter}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
        <div>
          {tab == "1" ? (
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label={t("Bulk Disable")}
              outlined
              onClick={bulkDisableNow}
            />
          ) : (
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label={t("Bulk Enable")}
              outlined
              onClick={bulkEnableNow}
            />
          )}
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab label={t("Available offers")} value="1" />
              <Tab label={t("Disabled offers")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              {loading ? (
                <>{t("loading ...")}</>
              ) : (
                <DataTable
                  value={allOffers}
                  paginator
                  rows={10}
                  tableStyle={{ minWidth: "50rem" }}
                  loading={loading}
                  loader={t("Loading...")}
                  dataKey="id"
                  filters={filters}
                  globalFilterFields={[
                    "id",
                    "offer_name",
                    "devices",
                    "countries",
                  ]}
                  header={header}
                  emptyMessage=  {t("No customers found.")}
                  selectionMode={"checkbox"}
                  selection={selectedOffers}
                  onSelectionChange={(e) => setSelectedOffers(e.value)}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column
                    field="id"
                    header={t("id")}
                    sortable
                    style={{ width: "25%" }}
                  >
                  </Column>
                  <Column
                    field="offer_name"
                    header=  {t("offer_name")}
                    body={titleOffer}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>
                  <Column
                    field="devices"
                    body={switchDevice}
                    header=  {t("device")}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>
                  
                  <Column
                    field="price_usd"
                    header=  {t("price ($)")}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>

                  
                  <Column
                    field="countries_array"
                    body={countries}
                    header=  {t("countries")}
                    style={{ width: "25%" }}
                  ></Column>
                </DataTable>
              )}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              {loading ? (
                "loading ..."
              ) : (
                <DataTable
                  value={offersDisabled}
                  paginator
                  stripedRows
                  rows={10}
                  tableStyle={{ minWidth: "50rem" }}
                  loading={loading}
                  dataKey="id"
                  filters={filters}
                  globalFilterFields={[
                    "id",
                    "offer_name",
                    "devices",
                    "countries",
                  ]}
                  header={header}
                  emptyMessage=  {t("No customers found.")}
                  selectionMode={"checkbox"}
                  selection={selectedOffers}
                  onSelectionChange={(e) => setSelectedOffers(e.value)}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column
                    field="id"
                    header=  {t("id")}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>
                  <Column
                    field="offer_name"
                    header=  {t("offer_name")}
                    body={titleOffer}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>
                  <Column
                    field="devices"
                    body={switchDevice}
                    header=  {t("device")}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>
                  
                  <Column
                    field="price_usd"
                    header=  {t("price ($)")}
                    sortable
                    style={{ width: "25%" }}
                  ></Column>
                  <Column
                    field="countries_array"
                    body={countries}
                    header=  {t("countries")}
                    style={{ width: "25%" }}
                  ></Column>
                </DataTable>
              )}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
      <Dialog open={openDialog} onClose={closeOfferDialog}>
        <DialogTitle>{selectedOffer && selectedOffer.offer_name}</DialogTitle>
        <DialogContent>
          <div>
            <img
              src={selectedOffer && selectedOffer.icon_url}
              alt="Offer Icon"
            />
          </div>
          <div>   
            <h3>   {t("Offer details")}</h3>
            <p>{selectedOffer && selectedOffer.offer_desc}</p>
            <p>
               {t("Offer url")}
              <a href={selectedOffer && selectedOffer.url_for_user}> {t("Click here")}</a>
            </p>
            <p>  {t("ID")}: {selectedOffer && selectedOffer.id}</p>
            <p>  {t("Platform")}: {selectedOffer && selectedOffer.platform}</p>
            <p>  {t("Country")}: {selectedOffer && selectedOffer.country}</p>
            <p>  {t("OS")}: {selectedOffer && selectedOffer.devices}</p>
            <p>  {t("Category")}: {selectedOffer && selectedOffer.category}</p> 

          </div>
            
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Offers;
