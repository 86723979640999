"use client";
import React, { useContext, useEffect, useState } from "react";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";
import { Box } from "@mui/material";  
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api"; 
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";
import { CheckSession } from "../../functions/forms";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
 

function Invoices() {
  const { getInvoices } = useContext(HomeContext);
  const { allInvoices } = useHomeContext();
  const { userLoggedIn , user } = useAuthContext();
  const { logout } = useContext(AuthContext);
  
  // data table
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const { t } = useTranslation();
  
  useEffect(() => {
     getInvoices().then(response => { })
     .catch(error => { 
      if(error.response?.data){
        toast.error(  t("An error occurred retrieving data") + error.response.data.message );
        console.log(error.response.data.message);
      }
      else{
        toast.error(t("An unknown error has occurred"));
        console.log(error.response.data.message);
      }
       }); 
    initFilters();
  }, []);
 
  
 
  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    console.log(value);
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      offer_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },

      // date: {
      //   operator: FilterOperator.AND,
      //   constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      // },
      // balance: {
      //   operator: FilterOperator.AND,
      //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      // },
      // status: {
      //   operator: FilterOperator.OR,
      //   constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      // },
      // activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      // verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  
 

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label={t("Clear")}
          outlined
          onClick={clearFilter}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
       
      </div>
    );
  };

  const header = renderHeader();

  return ( 
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}> 
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <DataTable
                value={allInvoices}
                paginator
                stripedRows 
                rows={10}
              
                tableStyle={{ minWidth: "50rem" }}
                loading={loading}
                dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "id_invo",
                  "paid_at",
                  "amount_due",
                  "status",
                  "note",
                  "payment_Method"
                ]}
                header={header}
                emptyMessage="No customers found."  
              >
                  
                <Column
                  field="id_invo"
                  header="id"
                  sortable
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="created_at"
                  header={t("created_at")}
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="paid_at"
                  header={t("paid_at")}
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="amount_due"
                  header={t("amount_due")}
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="payment_Method"
                  header={t("payment_Method")}
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="note"
                  header={t("note")}
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="status"
                  header={t("status")}
                  sortable
                  style={{ width: "25%" }}
                />
                
                 
              </DataTable>
            </div> 
      </Box>
    </div> 
  );
}

export default Invoices;
