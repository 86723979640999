import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
 

const DateRangePickerDropdown = ({ selectedRange, onDateRangeChange }) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [startDate, setStartDate] = useState(selectedRange.startDate);
  const [endDate, setEndDate] = useState(selectedRange.endDate);

  const dropdownRef = useRef();
  const formattedStartDate = format(startDate, "dd. MMM. yyyy");
  const formattedEndDate = format(endDate, "dd. MMM. yyyy");

  useEffect(() => {
    const handleClick = (event) => {
      if (dropdownRef.current.contains(event.target)) {
        return;
      }
      setShowDateRangePicker(false);
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
  
    setStartDate(startDate);
    setEndDate(endDate);
    onDateRangeChange({ startDate, endDate });
  };

  const toggleDropdown = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  return (
    <div ref={dropdownRef}>
      <button onClick={toggleDropdown}>
        {formattedStartDate} - {formattedEndDate}
      </button>
      {showDateRangePicker && (
        <div
          style={{
            position: "absolute",
            zIndex: 9999,
            marginTop: "10px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            background: "#fff",
          }}
        >
          <DateRangePicker
            ranges={[
              {
                startDate,
                endDate,
                key: "selection",
              },
            ]}
            onChange={handleSelect}
            // onChange={item => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction="vertical"
            maxDate={new Date()}  
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePickerDropdown;