import { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import toast from "react-hot-toast";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

export const PaymentMethod = () => {
  const { updatePayment } = useContext(HomeContext);
  const { dataUser, isLoading } = useHomeContext();
  const [selectedPayment, setSelectedPayment] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(); 
  const [additionalDetails, setAdditionalDetails] = useState(null);

  useEffect(() => { 
    
    let   parsedData ;
    if(dataUser.user?.payment_detail?.additional_details){
      parsedData = JSON.parse(dataUser.user?.payment_detail?.additional_details);
    }
    setAdditionalDetails(parsedData);
  }, [dataUser]);
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handlePaymentDetailsChange = (event) => {
    setPaymentDetails(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDetails = {};
    setLoading(true);
    // جمع قيم الحقول في الكائن formDetails
    selectedPaymentDetails.details.forEach((detail) => {
      formDetails[detail.name_details] =
        event.target[detail.name_details].value;
    });

    // تحديث الملف الشخصي بقيمة طريقة الدفع المحددة وتفاصيل الدفع
    const res = await updatePayment({
      payment_id: selectedPayment,
      payment_details: paymentDetails,
      ...formDetails,
    });
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const selectedPaymentDetails = dataUser?.payment?.find(
    (payment) => payment.id === selectedPayment
  );

  if (isLoading) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <div className="p-5 ">
          slug method : {dataUser?.user?.payment_detail?.payment_method_slug} <br />
          payment_details : {dataUser?.user?.payment_detail?.payment_details} <br />
           
          {additionalDetails &&
              Object.entries(additionalDetails).map(([key, value]) => (
                <div key={key}>
                  <span>{key}: </span>
                  <span>{value}</span>
                </div>
              ))
            }  
        </div>     
         <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t("Select Payment Method")}</InputLabel>
                  <Select
                    value={ 
                      selectedPayment
                    }
                    onChange={handlePaymentChange}
                  >
                    {dataUser?.payment?.map((payment) => (
                      <MenuItem
                        key={payment.id}
                        value={payment.id}
                        selected={
                          payment.id ===
                          dataUser?.user?.payment_detail?.payment_id
                        }
                      >
                        {payment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t("Payment Details")}
                  name="paymentDetails"
                  value={
                      paymentDetails
                  }
                  onChange={handlePaymentDetailsChange}
                  placeholder="Enter payment details"
                />
              </Grid>
              {selectedPaymentDetails &&
                selectedPaymentDetails.details.map((detail) => (
                  <Grid item md={6} xs={12} key={detail.id}>
                    <TextField
                      fullWidth
                      label={detail.label_details}
                      name={detail.name_details}
                      required={detail.required === "1"}
                      placeholder={detail.ph_details}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <LoadingButton
            type="submit"
            loading={loading}
            loadingIndicator={t("saving..")}
            variant="contained"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t("Save")}
          </LoadingButton>
        </CardActions>
      </Card>
    </form>
  );
};
