 import React from 'react'
import StatsComponent from './StatsComponent';
import { useHomeContext } from '../../contexts/HomeContext';

function Performance() {
    const { data } = useHomeContext();
  return (
    <div className="w-full md:w-1/2 lg:w-2/3 ">
              <h2 className="text-3xl font-bold mb-4">
                {data ? data.data.performance.title : ""}
              </h2>
              <div className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-5 gap-4">
                <StatsComponent
                  title={data.data.performance.click_count.title}
                  data={data.data.performance.click_count.data}
                  present={data.data.performance.click_count.present}
                />
                <StatsComponent
                  title={data.data.performance.completed_count.title}
                  data={data.data.performance.completed_count.data}
                  present={data.data.performance.completed_count.present}
                />
                <StatsComponent
                  title={data.data.performance.revenue.title}
                  data={data.data.performance.revenue.data.toFixed(2)}
                  present={data.data.performance.revenue.present}
                />
                <StatsComponent
                  title={data.data.performance.EPC.title}
                  data={data.data.performance.EPC.data.toFixed(2)}
                  present={data.data.performance.EPC.present}
                />
                <StatsComponent
                  title={data.data.performance.eCPM.title}
                  data={data.data.performance.eCPM.data.toFixed(2)}
                  present={data.data.performance.eCPM.present}
                />
              </div>
            </div>
  )
}

export default Performance