import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

export const Details = () => {
  const { updateProfile } = useContext(HomeContext);
  const { dataUser } = useHomeContext();
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    country: "",
    city: "",
    address: "",
    company_name: "",
    company_tax_number: "",
    mobile: "",
    skype: "",
    zip_code: "",
    type_account: 0,
  });

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (dataUser)
      setValues((prevState) => ({
        ...prevState,
        first_name: dataUser.user.first_name ?? "",
        last_name: dataUser.user.last_name ?? "",
        country: dataUser.user.country ?? "",
        city: dataUser.user.city ?? "",
        address: dataUser.user.address ?? "",
        company_name: dataUser.user.company_name ?? "",
        company_tax_number: dataUser.user.company_tax_number ?? "",
        mobile: dataUser.user.mobile ?? "",
        skype: dataUser.user.skype ?? "",
        telegram: dataUser.user.telegram ?? "",
        zip_code: dataUser.user.zip_code ?? "",
        type_account: dataUser.user.type_account ?? 0,
      }));
  }, [dataUser]);
  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await updateProfile(values);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  helperText="Please specify the first name"
                  label={t("First name")}
                  name="first_name"
                  onChange={handleChange}
                  required
                  value={values.first_name}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("Last name")}
                  name="last_name"
                  onChange={handleChange}
                  required
                  value={values.last_name}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("Phone Number")}
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={values.mobile}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("Country")}
                  name="country"
                  onChange={handleChange}
                  value={values.country}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("city")}
                  name="city"
                  onChange={handleChange}
                  value={values.city}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("address")}
                  name="address"
                  onChange={handleChange}
                  value={values.address}
                />
              </Grid>
              {values.type_account == 1 ? (
                <>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("company name")}
                      name="company_name"
                      onChange={handleChange}
                      value={values.company_name}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("company Tax Number")}
                      name="company_tax_number"
                      onChange={handleChange}
                      value={values.company_tax_number}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("skype")}
                  name="skype"
                  onChange={handleChange}
                  value={values.skype}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("telegram")}
                  name="telegram"
                  onChange={handleChange}
                  value={values.telegram}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("zip_code")}
                  name="zip_code"
                  onChange={handleChange}
                  value={values.zip_code}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label={t("type Account")}
                  name="type_account"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.type_account}
                >
                  <option value="0">Personal</option>
                  <option value="1">Company</option>
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <LoadingButton
            type="submit"
            loading={loading}
            loadingIndicator="saving.."
            variant="contained"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Save details
          </LoadingButton>
        </CardActions>
      </Card>
    </form>
  );
};
