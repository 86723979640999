import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n"; // استيراد i18n بدلاً من resources من i18n.js
import { AuthContext, useAuthContext } from "../contexts/AuthContext";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { allLangs } = useAuthContext(); 
  const { changeLang } = useContext(AuthContext);

  const getLanguageName = (language) => {
    const translation = i18n.getResourceBundle(language, "translation");
    return translation.languageName;
  };
 

  return (
    <div>
      {allLangs ? allLangs.map((language,i) => (<div  key={i}>
        <button
         
          onClick={() => changeLang(language)}
        >
          {getLanguageName(language)}
        </button> <br /></div>
      )) : ""}

    </div>
  );
}

export default LanguageSwitcher;