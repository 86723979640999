import React, { useEffect } from 'react'
 import { CheckSession } from '../../functions/forms';
import Cookies from 'js-cookie';

function NotLogged() {
   
    useEffect(() => {
      setTimeout(function () {
        console.log('Hello world')
      }, 1000)
        
    }, [])
    
  return (
    <div>يجب تسجيل الدخول</div>
  )
}

export default NotLogged