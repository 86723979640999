"use client";
import React, { useContext, useEffect, useState } from "react";
import Performance from "../../components/home/Performance";
import News from "../../components/home/News";
import ChartComponent from "../../components/home/ChartComponent";
import TopOffers from "../../components/home/TopOffers";
import DateRangePickerDropdown from "../../components/DateRangePickerDropdown";
import { format } from "date-fns";
import TableCountry from "../../components/home/TableCountry";
import ChartDoughnut from "../../components/home/ChartDoughnut";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext"; 
import toast from "react-hot-toast";
import WorldMap from "../../components/home/WorldMap";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { getData } = useContext(HomeContext);
  const { data } = useHomeContext();
  const { t } = useTranslation();

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
    key: "selection",
  });
  useEffect(() => {
    // CheckSession(userLoggedIn,user);
  }, []);

  useEffect(() => {
    getData(selectedRange)
      .then((response) => {})
      .catch((error) => {
        if (error.response?.data) {
          toast.error(
            t("An error occurred retrieving data") + error.response.data.message
          );

          console.log(error.response.data.message);
        } else {
          toast.error(t("An unknown error has occurred"));
          console.log(error);
        }
      }); // قم بإرسال البارامترات المحدثة
    console.log(1);
  }, [selectedRange]); // قم بإضافة selectedRange كوابل الاعتماد للـ useEffect

  const handleDateRangeChange = (range) => {
    console.log(3);
    setSelectedRange((prevState) => ({
      ...prevState,
      startDate: format(range.startDate, "yyyy-MM-dd") ?? "",
      endDate: format(range.endDate, "yyyy-MM-dd") ?? "",
    }));
  };

  if (!data) {
    return <>{t("loading ...")}</>;
  }
  return (
    <>
      <section className="dashboard">
        <div className=" top-actions flex flex-col md:flex-row">
          <div className="relative">
            <div className="top-10 bg-white rounded-lg shadow-lg z-10">
              <DateRangePickerDropdown
                selectedRange={selectedRange}
                onDateRangeChange={handleDateRangeChange}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-[20px]">
          <div className="col-span-1 md:order-first md:col-span-3">
            <div className="card ">
              <Performance />
            </div>
          </div>

          <div className="col-span-1 order-first md:col-span-2">
            <div className="card ">
              <News />
            </div>
          </div>
          <div className="col-span-1 md:col-span-5 xl:col-span-3">
            <div className="card ">
              <ChartComponent />
            </div>
          </div>
          <div className="col-span-1 md:col-span-5 xl:col-span-2 grid grid-cols-1 md:grid-cols-5">
            <div className="col-span-1 md:col-span-3 xl:col-span-5">
              <div className="card ">
                <TopOffers />
              </div>
            </div>
            <div className="col-span-1 md:col-span-2 xl:col-span-5">
              <div className="card "></div>
              {/* Reversals */}
            </div>
          </div>
          <div className="col-span-1 md:col-span-3">
            <WorldMap />
            <div className="card ">
              <TableCountry />
            </div>
          </div>
          <div className="col-span-1 md:col-span-2">
            <div className="card ">
              <ChartDoughnut />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
